<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <main-dashboard-statistics
          :data="data.statisticsItems"
          :loading="loading['statistics']"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header class="mb-0 pb-0">
            <b-card-title>Eventos de hoy</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-button
                variant="primary"
                size="sm"
              >
                Ir al calendario
              </b-button>
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body mt-0 pt-0">
            <calendar :view="'dashboard'" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol,
} from 'bootstrap-vue'

import calendar from '../../apps/calendar/Calendar.vue'
import MainDashboardStatistics from './MainDashboardStatistics.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,

    MainDashboardStatistics,
    calendar,
  },
  data() {
    return {
      data: { statisticsItems: [] },
    }
  },
  computed: {
    loading() {
      return this.$store.state.generalBusiness.loading
    },
    customers() {
      return this.$store.state.customers.customer_list
    },
    resources() {
      return this.$store.state.resources.resource_list
    },
    services() {
      return this.$store.state.services.service_list
    },
    schedules() {
      return this.$store.state.schedules.schedule_list
    },
  },
  watch: {
    customers() {
      this.fillStatistics()
    },
    schedules() {
      this.fillStatistics()
    },
    resources() {
      if (this.resources.length) {
        this.$store.commit('calendar/SET_SELECTED_EVENTS', this.resources.map(r => ({
          _id: r._id,
          resourceName: r.resourceName,
          eventBackgroundColor: r.eventBackgroundColor,
          photoUrl: r.photoUrl,
        })))
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    fillStatistics() {
      this.data = {
        statisticsItems: [
          {
            icon: 'UserIcon ',
            color: 'light-primary',
            title: this.customers.length,
            subtitle: 'Clientes',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-info',
            title: this.services.length,
            subtitle: 'Servicios',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UsersIcon',
            color: 'light-danger',
            title: this.resources.length,
            subtitle: 'Empleados',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'CalendarIcon',
            color: 'light-success',
            title: this.schedules.length,
            subtitle: 'Citas',
            customClass: '',
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Estadísticas</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-overlay
            id="overlay-background"
            :show="loading"
            variant="light"
            opacity="1.00"
            blur="2px"
            rounded="sm"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-overlay>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {

  },
}
</script>
